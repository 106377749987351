import React, { useEffect, useState } from 'react'

function OrderDate({ order, setOrder }) {
    const createDateOrder = () => {
        const nowdate = new Date();
        const hours = nowdate.getHours();
        const day = nowdate.getDate() + 1;
        const year = nowdate.getFullYear();
        const month = nowdate.getMonth();
        const sevenDay = (num) => new Date(year, month, day + num).getDay() !== 1;
        const newDate = [
            sevenDay(2) && { data: new Date(year, month, day + 2).toISOString().split('T')[0], text: "послезавтра" },
            sevenDay(3) && { data: new Date(year, month, day + 3).toISOString().split('T')[0], text: new Date(year, month, day + 3).toISOString().split('T')[0] },
            sevenDay(4) && { data: new Date(year, month, day + 4).toISOString().split('T')[0], text: new Date(year, month, day + 4).toISOString().split('T')[0] }].filter(el => !!el)
        if (hours >= 16) {
            return newDate;
        } else {
            if (sevenDay(1)) newDate.unshift({ data: new Date(year, month, day + 1).toISOString().split('T')[0], text: "завтра" });
            return newDate;
        }
    }
    const [date, setDate] = useState(createDateOrder())

    useEffect(() => {
        setOrder({ ...order, delivery: date[0].data });
        console.log(order);
    }, [])


    return (<>
        <div className='order-location'>
            <div className='order-location-text'>Спасибо, ĸуда везти — запомнил. Готовы привезти вам воду {date[0].text}. Если хотите выбрать другую дату, уĸажите удобный день ниже.</div>
            <div className='order-location-text'>Выберите, когда вам удобно принять заказ?</div>
            <select value={order.delivery} onChange={(e) => setOrder({ ...order, delivery: e.target.value })} className={'select'}>
                {/* <option value={false}>Выберите дату из списка</option> */}
                {date.length > 0 && date.map((el, index) => (
                    index === 0 ?
                        <option value={el.data} key={"ord-loc" + el} selected="selected">{el.text}</option>
                        :
                        <option value={el.data} key={"ord-loc" + el}>{el.text}</option>
                ))}
            </select>
        </div></>);
}

export default OrderDate;