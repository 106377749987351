import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SingUp from '../components/sing-up/singup';
import Menu from "../components/menu/menu";
import Header from "../components/header/header";
import Order from '../modules/order/order';
import Address from '../modules/location/address';
import Info from '../modules/info/info'
import UserOrders from "../modules/user-order/user-order";
import Admin from "../modules/admin/admin";

function RouteApp() {
    return (
        <BrowserRouter>
            {/* <Header /> */}
            <Routes>
                <Route path='singup/*' element={<SingUp />} />
                <Route path='menu' element={<Menu />} />
                <Route path='info' element={<Info />} />
                <Route path='user/:id' >
                    <Route path='order/*' element={<Order />} />
                    <Route path='address' element={<Address />} />
                    <Route path='my-orders/*' element={<UserOrders />} />
                </Route>
                <Route path='admin/*' element={<Admin />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RouteApp;