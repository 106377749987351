import { Routes, Route, Link } from 'react-router-dom';
import Chats from "./chats";
import './chat.css'
import SingUp from './sing/singUp';


const Admin = () => {

    return (
        <div>
            <Routes >
                <Route index element={<>
                    <Link to={'chats'}><div className="Admin-panel btn btn-light btn-lg btn-rounded float-end message-btn">Чаты</div></Link>
                    <Link to={'singup'}><div className="Admin-panel btn btn-light btn-lg btn-rounded float-end message-btn">Вход/Регистрация</div></Link>
                </>
                } />
                <Route path='chats' element={<Chats />} />
                <Route path='singUp' element={<SingUp />} />
            </Routes>
        </div>
    );
}

export default Admin;