import { useState } from "react"
import "./user-order.scss"
import useTelegram from "../../hook/useTelegram";
import useCookie from "../../hook/cookie.ts";
import { useEffect, memo, useCallback } from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';


function UserOrderItem(props) {
    const { order, setOrderActive } = props;
    const [active, setActive] = useState(false)
    return (
        <>
            {<div className="user-order-item">
                <div className="user-order-text">Заказ № VC-100-{order?.id?.slice(0, 5).toUpperCase()}, от {new Date(order?.created_at).toISOString().split('T')[0]}</div>
                {active && (<div className="user-order-info">
                    <div>{`Адрес доставки: ${order?.location?.text ?? 'возможно был удален'}`}</div>
                    {order?.date_delivery && <div>{`Дата доставки: ${new Date(order?.date_delivery).toISOString().split('T')[0]}`}</div>}
                    {/* {order?.status && <div className={order?.status === 'cancellation' ? 'user-order-info-red' : order?.status === 'processing' ? 'user-order-info-yellow' : order?.status === 'execution' ? 'user-order-info-green' : 'user-order-info-gray'}>{`Статус заказа: ${order.status}`}</div>} */}
                    {order?.discount && <div>{`Скидка по заказу: ${order.discount}`}</div>}
                    <div>Товары:</div>
                    {order?.product?.map(el => (<div className="user-order-info-prod" key={el.id}>{`${el.title} - ${el.count} шт. = ${el.sum}`}&#8364;</div>)) ?? <div>пусто</div>}
                    <div>{`Сумма заказа: ${order?.price ?? 0}`}&#8364;</div>
                </div>)}
                {order?.location?.text ? (<div className="user-order-btn">
                    <Link to={'order'} onClick={() => setOrderActive(order)}><div className="button">Повторить заказ</div></Link>
                    <button className="button" onClick={() => setActive(!active)}>{active ? 'Скрыть информацию о заказе' : 'Просмотреть заказ'}</button>
                </div>) : (<div className="user-order-btn-1">
                    <button className="button" onClick={() => setActive(!active)}>{active ? 'Скрыть информацию о заказе' : 'Просмотреть заказ'}</button>
                </div>)}
            </div>}
        </>
    );
}

export default UserOrderItem;