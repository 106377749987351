import { useState, useEffect } from "react"


const ChatItem = ({ nameUser, el, fetchDataChat = Function.prototype }) => {

    useEffect(() => {
        let interval = setInterval(() => fetchDataChat(), 30000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <li className="d-flex justify-content-between mb-4">
            <div className="card mask-custom">
                <div className="card-header d-flex justify-content-between p-3"
                    style={{ "borderBottom": "1px solid rgba(255,255,255,.3)" }}>
                    <p className="fw-bold mb-0">{!el.user ? "Администратор" : nameUser}</p>
                    <p className="text-light small mb-0"><i className="far fa-clock"></i>{el?.created_at?.replace('T', " ").split('.')[0]}</p>
                </div>
                <div className="card-body">
                    <p className="mb-0">
                        {el?.text}
                    </p>
                </div>
            </div>
            {!el.user && <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-15.webp" alt="avatar"
                className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="60" />}
        </li>
    )
}

export default ChatItem;