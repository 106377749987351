import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './location.scss'

function OrderLocation({ addr, order, setOrder }) {
    const [active, setActive] = useState(false);

    const setAddress = (e) => {
        if (e.target.value === 'Ввести вручную') {
            setActive(true);
        } else {
            setActive(false);
        }
        if (e.target.value !== null || e.target.value !== 'Ввести вручную')
            setOrder({ ...order, location: { id: (e.target.value === 'Выберите адрес из списка' || e.target.value === 'Ввести вручную') ? null : e.target.value } })
    }

    useEffect(() => {
        if (addr.length < 1) {
            setActive(true);
        } else {
            setOrder({ ...order, location: { id: addr?.[0]?.id } })
        }
    }, [])

    return (<>
        {((order?.location?.id && order?.location.id !== 'Выберите адрес из списка') || order?.locationText?.length > 0) && <div className="order-bottom"><Link to={'date'}>Выбрать дату</Link></div>}
        <div className="order-location">
            {/* <div>Рассĸажите, ĸуда привезти воду. Можете добавить ĸ адресу полезные ориентиры, например: «дом за заправĸой Eco, первый подъезд в арĸе слева, ĸод домофона 4321» или «Вход по внешней лестнице на третий этаж» — таĸ водителю будет проще вас найти.</div> */}
            <select onChange={(e) => setAddress(e)} className={'select'}>
                {order?.location?.text && <option value={order?.location} selected="selected">{order?.location.text}</option>}
                {/* <option value={null}>{'Выберите адрес из списка'}</option> */}
                {addr.map((el, index) => (
                    (index === 0 && !order?.location?.text) ?
                        <option value={el.id} key={"ord-loc" + el.id} selected="selected">{el.text}</option> : <option value={el.id} key={"ord-loc" + el.id} >{el.text}</option>
                ))}
                <option value={'Ввести вручную'} selected={addr.length < 1 ? "selected" : null}>{'Ввести вручную'}</option>
            </select>
            {active && (<>
                <div className="order-location-text">
                    <span>Рассĸажите, ĸуда привезти воду. Можете добавить ĸ адресу полезные ориентиры, например: «дом за заправĸой Eco, первый подъезд в арĸе слева, ĸод домофона 4321» или «Вход по внешней лестнице на третий этаж» — таĸ водителю будет проще вас найти.</span>
                </div>
                <textarea placeholder="Уĸажите адрес с пояснениями для водителя" value={order.locationText} onChange={(e) => setOrder({ ...order, locationText: e.target.value })} className="order-location-textarea select" />
            </>
            )}
        </div>
    </>);
}

export default memo(OrderLocation);