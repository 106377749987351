import { useState } from "react"
import "./order.scss"
import useTelegram from "../../hook/useTelegram";
import useCookie from "../../hook/cookie.ts";
import { useEffect, memo, useCallback } from "react";
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import OrderItem from "./order-item";
import OrderBottom from './order-bottom'
import OrderLocation from "./location/location";
import OrderDate from "./date/date";

const Order = (props) => {
    const { dataOrder } = props;
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [order, setOrder] = useState(dataOrder || { product: [], location: {}, price: 0, locationText: "", user: { id } });
    const [price, setPrice] = useState(0);
    const [productCount, setProductCount] = useState(false);
    const [addr, setAddr] = useState([]);

    const { tg, queryId, user } = useTelegram();
    useEffect(() => {
        let sum = 0;
        order?.product?.forEach(el => {
            sum += el.price * el.count;
        })
        setOrder({ ...order, price: sum.toFixed(2) })
        setPrice(sum.toFixed(2))
    }, [order.product])

    useEffect(() => {
        tg.MainButton.setParams({
            text: "[" + price + '€] Подтвердить заĸаз',
        })
    }, [price])
    const { setId, getName } = useCookie()

    useEffect(() => {
        async function fetchData() {
            // const id = getName("authentication");
            const resProduct = await fetch('https://tg.voda.cool/api/product');
            const dataProduct = await resProduct.json();
            setProduct(dataProduct);
            const resAddr = await fetch('https://tg.voda.cool/api/location/user/' + id);
            const dataAddr = await resAddr.json()
            setAddr(dataAddr);
        }
        fetchData();
        if (!!dataOrder?.price > 0) { setPrice(dataOrder?.price) } else {
            setPrice(0);
        }
    }, [])

    useEffect(() => {
        // async function start() {
        //     await product.filter(el => el.title === "Бутилированная вода | 10 литров").forEach(async el => await setOrder({ ...order, product: [...order.product, { id: el?.id, title: el?.title, price: el.price, count: 3 }] }));
        // }
        // !dataOrder && start();
    }, [product])

    const onSendData = useCallback(async () => {
        const productData = order.product.map(el => `${el.title} - ${el.count} шт. = ${(el.price * el.count).toFixed(2)}€`).join('\n');
        await tg.showConfirm(`Давайте проверим ваш заказ:\n\n${productData}\nДоставка: бесплатно\n\nИТОГО: ${order.price}€`, async (res) => {
            if (!!res) {
                if (queryId) {
                    const data = {
                        order,
                        queryId,
                        telegram_id: user.id,
                    }
                    await fetch('https://tg.voda.cool/api/web-app', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data)
                    });
                } else {
                    await tg.sendData(JSON.stringify(order));
                }
            }
        });
    }, [order])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData]);

    useEffect(() => {
        let count1 = 0;
        let count2 = 0;
        order.product.filter(el => el.title === "Бутилированная вода | 10 литров" || el.title === "Бутилированная вода | 19 литров").map(item => {
            if (item.title === "Бутилированная вода | 19 литров" && item.count > 1) {
                count1 = item.count;
                return setProductCount(true);
            } else if (item.title === "Бутилированная вода | 10 литров" && item.count > 2) {
                count2 = item.count;
                return setProductCount(true);
            }
        });
        if (count1 > 1 || count2 > 2) {
            return setProductCount(true);
        }
        return setProductCount(false);
    }, [order.product, productCount, product])

    useEffect(() => {
        if (order?.product?.length > 0 && ((!!order?.location && order?.location?.id !== 'Выберите адрес из списка' && !!order?.location?.id) || !!order?.locationText) && !!order?.delivery && order?.delivery !== 'Default') {
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    }, [order])

    return (
        <>
            <Routes >
                <Route path='location' element={<OrderLocation order={order} addr={addr} setOrder={setOrder} />} />
                <Route path='location/date' element={<OrderDate order={order} setOrder={setOrder} />} />
                <Route index element={<>
                    {productCount && <OrderBottom price={price} title="Уĸазать адрес" />}
                    <div className="order-wrapper">
                        {product.length > 0 ? product.filter(el => el.status !== 'onebyorder').map(el => <OrderItem {...el} order={order} setOrder={setOrder} key={"order" + el.id} />) : "Товары отсутствуют"}
                        {product.length > 0 ? product.filter(el => el.status === 'onebyorder').map(el => <OrderItem {...el} order={order} setOrder={setOrder} key={"order" + el.id} />) : "Товары отсутствуют"}
                    </div></>} />
            </Routes>
        </>
    );
}

export default memo(Order);