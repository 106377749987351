import { useState, useEffect } from "react"
import ChatItem from './chat-item'

const Chats = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [chat, setChat] = useState([{}, {}]);
    const [chatMessage, setChatMessage] = useState([{}, {}]);
    const [text, setText] = useState("");
    const [nameUser, setNameUser] = useState("");
    const [chatId, setChatId] = useState();
    const [active, setActive] = useState(false);
    const [test, setTest] = useState({ 'visibility': 'visible' });
    const [activeModal, setActiveModal] = useState(false);

    async function fetchData() {
        const resOrder = await fetch('https://tg.voda.cool/api/admin/chats', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const dataChat = await resOrder.json();
        return await setChat(dataChat);
    }
    async function fetchDataChat() {
        const id = window.location.search.substring(1).replace('chat=', '').split(',')[0];
        const resOrder = await fetch('https://tg.voda.cool/api/admin/chat/' + id);
        const dataChat = await resOrder.json()
        return await setChatMessage(dataChat);
    }
    async function sendMessage() {
        const id = window.location.search.substring(1).replace('chat=', '').split(',')[0];
        await fetch('https://tg.voda.cool/api/admin/chat/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        }).then(el => el.ok && setText(''));
        await fetchDataChat();
    }

    useEffect(() => {
        fetchData()
        setChatId(window.location.search.substring(1).replace('chat=', '').split(',')[0]);
        setNameUser(window.location.search.substring(1).replace('username=', '').split(',')[1]);
    }, [])

    useEffect(() => {
        if (!!chatId) {
            fetchDataChat();
        }
    }, [chatId])

    useEffect(() => {
        if (!active) {
            setTimeout(() => setTest({ 'visibility': 'hidden' }), 1000);
        } else {
            setTest({ 'visibility': 'visible' })
        }
    }, [active])

    return (
        <section className="gradient-custom">
            <div className="container py-5">
                <div className="row">
                    <nav className="navbar navbar-light bg-light fixed-top">
                        <div className="container-fluid">
                            <div className="navbar-brand">Меню</div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" onClick={() => setActive(true)}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={"offcanvas offcanvas-end" + (active ? ' show' : '')} tabindex="-1" style={test} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" >
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Client</h5>
                                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setActive(false)}></button>
                                </div>
                                <div className="offcanvas-body">
                                    <form className="d-flex">
                                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                        <button className="btn btn-outline-success" type="submit">Search еще не работает!"</button>
                                    </form>
                                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                        {
                                            chat.length > 0 && chat.map((el, index) =>
                                                <li className="p-2 border-bottom" style={{ "borderBottom": "1px solid rgba(255,255,255,.3) !important" }} key={"client_" + index}>
                                                    <a href={`?chat=${el.telegram_id},username=${el?.username ?? el?.name ?? el?.telegram_id}`} className="d-flex justify-content-between link-dark" onClick={() => setActive(!active)}>
                                                        <div className="d-flex flex-row">
                                                            <img src={`https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-${index % 14 === 0 ? 16 : index % 14}.webp`} alt="Avatar_Client"
                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="60" />
                                                            <div className="pt-1">
                                                                <p className="fw-bold mb-0">{`${el?.username ?? el?.name ?? ""} - ${el?.telegram_id ?? ""}`}</p>
                                                                <p className="small text-dark">Зарегистрирован: {el?.created_at?.replace('T', " ").split('.')[0]}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="chat-list">
                        <h5 className="font-weight-bold mb-3 text-center text-white chat-send"> <span>Chat</span> <span className="send-open" onClick={() => setActiveModal(true)}>Ответить</span></h5>
                        <ul className="list-unstyled text-white" style={{
                            'paddingRight': '1rem'
                        }}>
                            {
                                chatMessage.length > 0 && chatMessage.map((el, index) =>
                                    <ChatItem el={el} nameUser={nameUser} key={"msg_" + index} fetchDataChat={fetchDataChat} />
                                )
                            }
                        </ul>
                        <div class={"modal fade" + (activeModal ? ' show' : '')} id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" style={activeModal ? { "display": "block" } : { "display": "none" }} aria-modal="true" role="dialog">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalCenterTitle">Сообщение</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setActiveModal(!activeModal); setText('') }}></button>
                                    </div>
                                    <div class="modal-body">
                                        <div className="form-outline form-dark">
                                            <textarea className="form-control form-control-text" id="textAreaExample3" rows="4" value={text} onChange={(e) => setText(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { setActiveModal(!activeModal); setText('') }}>Close</button>
                                        <button type="button" class="btn btn-primary" onClick={() => sendMessage()}>Отправить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
}

export default Chats;