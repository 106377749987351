import { useState, useEffect } from "react";
import './singUp.scss';
import { Link, useNavigate } from "react-router-dom";

const SingUp = () => {
    const [active, setActive] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setActive(window.location.search.substring(1));
    }, [])

    async function login() {
        return await fetch('https://tg.voda.cool/api/admin/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
    }
    async function registration() {
        return await fetch('https://tg.voda.cool/api/admin/reg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!active) {
            const data = await login();
            if (await data.ok) {
                alert('Вход выполнен!')
                navigate("/admin")
            } else {
                alert(await data.statusText)
            }
        } else {
            const data = await registration();
            if (await data.ok) {
                alert('Регистрация пройдена успешно!');
                navigate("/admin");
            } else {
                alert(await data.statusText);
                alert("Ошибка отправки запроса");
            }
        }
    };

    return (
        <div className="Admin-panel-2" >
            <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="nav-card">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item text-center">
                            <span className={!active ? "nav-link active btl" : "nav-link btr"} id="pills-home-tab" data-toggle="pill" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setActive(false)}>Login</span>
                        </li>
                        <li className="nav-item text-center">
                            <span className={active ? "nav-link active btl" : "nav-link btr"} id="pills-profile-tab" data-toggle="pill" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setActive(true)}>Signup</span>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className={!active ? "tab-pane fade show active" : "tab-pane fade"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                            <form className="form px-4 pt-5" onSubmit={handleSubmit}>
                                <input type="text" name="" className="form-control" placeholder="Email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                                <input type="text" name="" className="form-control" placeholder="Password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />
                                <button className="btn btn-dark btn-block" type="submit">Login</button>
                            </form>
                        </div>
                        <div className={active ? "tab-pane fade show active" : "tab-pane fade"} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <form className="form px-4" onSubmit={handleSubmit}>
                                <input type="text" name="email" className="form-control" placeholder="Email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                                <input type="text" name="password" className="form-control" placeholder="Password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />
                                <input type="text" name="code" className="form-control" placeholder="Code" value={data.code} onChange={(e) => setData({ ...data, code: e.target.value })} />
                                <input type="text" name="code" className="form-control" placeholder="Name" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
                                <input type="text" name="code" className="form-control" placeholder="Telegram_id" value={data.telegram_id} onChange={(e) => setData({ ...data, telegram_id: e.target.value })} />
                                <button className="btn btn-dark btn-block" type="submit">Signup</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SingUp;
