import { useState, useEffect, memo } from "react"

const OrderItem = (props) => {
    const { avatar, price, count, order, setOrder, id, title, status, spoiler } = props;
    const [activeSpoiler, setActiveSpoiler] = useState(false);

    const createProductOrder = ({ id }) => {
        let countValue = 0;
        order.product.forEach(el => {
            if (el.id === id) return countValue = el.count
        });
        return countValue ?? 0;
    }
    const [value, setValue] = useState(createProductOrder({ id }))

    useEffect(() => {
        if (!order.product.some(el => el.id === id) && value > 0) {
            return setOrder({ ...order, product: [...order.product, { id, title, price, count: value }] });
        } else if (value === 0) {
            const newProduct = order.product.filter(el => el.id !== id)
            return setOrder({ ...order, product: newProduct });
        } else {
            const newProduct = order.product.map(el => el.id === id ? { ...el, count: value } : el)
            return setOrder({ ...order, product: newProduct });
        }
    }, [value])

    // useEffect(() => {
    //     if (title === "Бутилированная вода | 10 литров") {
    //         setValue(3);
    //     }
    // }, []);


    if (status === 'onebyorder') {
        return (
            <div className="order-item check-in">
                <div class="checkbox"><input type="checkbox" name="meal" id={"checkIn" + id} onChange={() => setValue(value === 1 ? 0 : 1)} checked={!!value} />
                    <label for={"checkIn" + id}>{title} [+{price}&#8364;]</label>
                </div>
                {spoiler && <div onClick={() => setActiveSpoiler(!activeSpoiler)}>{!activeSpoiler ? <span style={{ color: "red" }}>Что это такое?👈</span> : spoiler}</div>}
            </div>
        )
    } else {
        return (
            <div className="order-item">
                <img alt="Картинка" src={avatar?.path ? '/api/' + avatar?.path : '/image/no_image.png'} className="order-item-img" />
                <div>{title}</div>
                <div className="order-item-price">
                    <div className="item-price">{price} &#8364;</div>
                    <div className="order-item-count-item">
                        <div className="order-item-count-btn">
                            <div onClick={() => value > 1 && setValue((value === 2 && title === "Бутилированная вода | 19 литров") ? value - 2 : ((value === 3 && title === "Бутилированная вода | 10 литров") ? value - 3 : value - 1))} className="btn-minus">-</div>
                            <div className="order-item-value">{value}</div>
                            <div onClick={() => setValue((value === 0 && title === "Бутилированная вода | 19 литров") ? value + 2 : ((value === 0 && title === "Бутилированная вода | 10 литров") ? value + 3 : value + 1))} className="btn-plus">+</div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default memo(OrderItem);