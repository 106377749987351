import { Cookies } from "react-cookie";
import uuid from "react-uuid";

export default function useCookie(): {
  getAll;
  cookie;
  getName;
  setName;
  setId;
} {
  const cookie = new Cookies();

  const getAll = () => {
    return cookie.getAll();
  };

  const getName = (name: string) => {
    return cookie.get(name);
  };

  const setName = (name: string, value: string | number) => {
    return cookie.set(name, value);
  };

  const setId = () => {
    const newUuid = uuid();
    cookie.set("authentication", newUuid);
    return newUuid;
  };
  return {
    getAll,
    cookie,
    getName,
    setName,
    setId,
  };
}
