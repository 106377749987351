import { useState } from "react"
import "./user-order.scss"
import useTelegram from "../../hook/useTelegram";
import useCookie from "../../hook/cookie.ts";
import { useEffect, memo, useCallback } from "react";
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';


function UserOrderHeader({ level, discount, count }) {
    return (<div className="user-order-header-wrapper">
        {`Уровень: ${level ?? 1}, Скидка: ${discount ?? 0}%, Количество заказов: ${count ?? 0}`}
    </div>);
}

export default UserOrderHeader;