import React, { useState, useEffect } from "react";
import AddressItem from "./address-item";
import useCookie from "../../hook/cookie.ts";
import "./address.scss"
import { useParams } from "react-router-dom";
import useTelegram from "../../hook/useTelegram";

function Address() {
    const { tg, } = useTelegram();

    const [addr, setAddr] = useState([])
    const [addrActive, setAddrActive] = useState(false);
    const { id } = useParams();
    const [createAddr, setCreateAddr] = useState('');
    const [createAddrActive, setCreateAddrActive] = useState(false);

    async function fetchData() {
        const resAddr = await fetch('https://tg.voda.cool/api/location/user/' + id);
        const dataAddr = await resAddr.json()
        setAddr(dataAddr);
    }
    async function sendCreateData(method, { location_id = null, text = null }) {
        await tg.sendData(JSON.stringify({
            method,
            location_id,
            text,
            location: method === "POST" ? {
                text: createAddr,
                user: { id },
            } : null,
            id,
        }));
    }
    useEffect(() => {
        fetchData();
    }, [])

    async function createAddAddress() {
        if (createAddr.length > 4) {
            await sendCreateData("POST", { location_id: null, text: null });
        }
    }
    function cancelAddAddress() {
        setCreateAddr('');
        return setCreateAddrActive(!createAddrActive);
    }

    return (
        <>
            <div className="address-wrapper">
                <div>Список сохраненных адресов:</div>
                {/* <button className="button" onClick={() => setAddrActive(!addrActive)}>{!addrActive ? 'Развернуть список' : 'Свернуть список'}</button> */}
                {addr.length > 0 ? addr.map((el) =>
                (
                    <AddressItem {...el} key={el.id} sendCreateData={sendCreateData} />
                )) : <div>Список пуст!</div>}
                {/* <div>Добавить новый адресов</div>
                <textarea className="address-textarea select" value={createAddr} minLength={4} onChange={(e) => setCreateAddr(e.target.value)} />
                <div className="address-create">
                    <button className="button" onClick={() => cancelAddAddress()}>Отмена</button>
                    <button className="button" onClick={() => createAddAddress()}>Сохранить</button>
                </div> */}
            </div>
        </>);
}

export default Address;