import React, { useEffect, useState } from 'react'
import './info.scss'

function Info() {
    return (<>
        <div className='info-wrapper'>
            <div>
                Пара слов о нас:
                мы доставляем чистую воду из горных источников, расположенных между национальным парком Биоградска-гора и рекой Тара.
                Вся вода добывается под строгим производственным контролем HACCP, защищена сертификатами контроля качества  ISO 9001:2015 и SK11/1548.
                Такую воду можно пить, готовить на ней еду, давать детям, она предназначена для ежедневного применения и полностью безопасна.
                А еще она вкусная!
            </div>
            <div>
                Мы доставляем воду в одноразовой 10-литровой таре.
                Никаких залогов за бутылки, никаких сомнений в чистоте тары.
                Такую бутылку после использования можно просто выкинуть.
                Но если хотите, можете отдать пустые бутылки нам — мы отвозим их на станцию Коник в Подгорице, откуда пластик отправляется на переработку и повторное использование.
                Наши бутылки отлично подходят ко всем видам кулеров, их можно использовать с автоматическими помпами.
                Кстати, помпу вы тоже можете заказать у нас при необходимости.
            </div>
        </div></>);
}

export default Info;