import React, { useState, useEffect } from "react";

function AddressItem(props) {
    const { text, id, sendCreateData } = props;
    const [textActive, setTextActive] = useState(false);
    const [newText, setNewText] = useState(text);

    const clickSave = async () => {
        sendCreateData("PUT", { location_id: id, text: newText })
        setTextActive(!textActive);
    }

    return (
        <div className="address-item">
            {textActive ?
                (<textarea className="select address-textarea " value={newText} minLength={4} onChange={(e) => setNewText(e.target.value)} />) :
                (<div>{newText}</div>)}
            {textActive && (<button className="button" onClick={() => clickSave()}>Сохранить</button>)}
            <div>
                <button className="button" onClick={() => setTextActive(!textActive)}>{!textActive ? 'Редактировать' : 'Отмена'}</button>
                <button className="button" onClick={() => sendCreateData("DELETE", { location_id: id })}>Удалить</button>
            </div>
        </div>);
}

export default AddressItem;