import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";

const Username = (props) => {
    const { data, setData } = props
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setData({ ...data, usernameActive: true });
        navigate("singup/1");
    };
    const handleChange = (e) => {
        setData({ ...data, username: e.target.value.trim() });
    }
    return (
        <form onSubmit={handleSubmit}>
            <div style={{ fontSize: "0.8rem" }}>Представьтесь, пожалуйста, чтобы я не чувствовал себя грубияном</div>
            <div>
                <input
                    type="text"
                    id="username"
                    className="input"
                    placeholder="Ваше имя"
                    value={data.username}
                    style={{ fontSize: "1.2rem" }}
                    onChange={handleChange}
                    required
                />
            </div>
            <button className="button" type="submit">
                <span>Продолжить</span>
            </button>
        </form>
    );
}

export default Username;