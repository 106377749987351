import "./user-order.scss"
import useTelegram from "../../hook/useTelegram";
import useCookie from "../../hook/cookie.ts";
import { useEffect, memo, useCallback, useState } from "react";
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import UserOrderHeader from "./user-order-header";
import UserOrderItem from "./user-order-item";
import Order from "../order/order"


function UserOrders() {

    const [order, setOrder] = useState([]);
    const [count, setCount] = useState(0);
    const { id } = useParams();

    const [orderActive, setOrderActive] = useState({});


    useEffect(() => {
        async function fetchData() {
            const resOrder = await fetch('https://tg.voda.cool/api/order/user/' + id);
            const dataOrder = await resOrder.json()
            setOrder(dataOrder.order);
            setCount(dataOrder.count);
        }
        fetchData();
    }, [])

    return (<>
        <Routes >
            <Route index element={(<div className="user-order-wrapper">
                <UserOrderHeader count={count} level={order?.[0]?.user?.level} discount={order?.[0]?.user?.discount} />
                {order?.length > 0 ? order?.map(el =>
                    <UserOrderItem order={el} key={"order" + el.id} setOrderActive={setOrderActive} />
                ) : <div>Список заказов пуст</div>}
            </div>)} />
            <Route path='order/*' element={<Order dataOrder={orderActive} />} />
        </Routes>
    </>);
}

export default UserOrders;