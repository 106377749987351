import { useState } from "react"
import "./singUp.scss"
import Username from "./username-singup";
import { Routes, Route } from 'react-router-dom';
import CitySingUp from "./city-singup";


const singUp = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [data, setData] = useState({});

    return (
        <div className="singup-wrapper">
            <Routes >
                <Route index element={<Username data={data} setData={setData} />} />
                <Route path='singup/1' element={<CitySingUp data={data} setData={setData} />} />
            </Routes>
        </div>
    );
}

export default singUp;