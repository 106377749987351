import { useEffect, useState } from "react";
import "./App.css";
import Bottom from "./components/bottom/bottom";
import RouteApp from "./route/route";
import useTelegram from "./hook/useTelegram";
import { CookiesProvider } from "react-cookie";
import "./modules/select.scss";


function App() {
  const { tg } = useTelegram();

  const [viewportHeight, setViewportHeight] = useState('390px')

  useEffect(() => {
    tg.ready();
    tg.expand();
    setViewportHeight(`${tg.viewportHeight > 420 ? 420 : tg.viewportHeight.toFixed(2) - 20}px`);
  }, []);

  return (
    <CookiesProvider>
      <div className="App">
        <RouteApp />
        <Bottom />
      </div>
    </CookiesProvider>
  );
}

export default App;
