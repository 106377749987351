import { useState, useEffect, useCallback } from "react"
import useTelegram from "../../hook/useTelegram";
import useCookie from "../../hook/cookie.ts"

const CitySingUp = (props) => {
    const { user, onClose, queryId, tg } = useTelegram();
    const { data, setData } = props;
    const [activeCity, setActiveCity] = useState(false);
    const onSendData = useCallback(async () => {
        await tg.sendData(JSON.stringify(data));
    }, [data])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if ((data.city && data.city !== 'Default' && data.city !== "Выберите город из списка") || data.cityText?.length >= 3) {
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    }, [data])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Подтвердить'
        })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setData({ ...data, usernameActive: true });
        onClose();
    };
    const onChangeCity = (e) => {
        if (e.target.value === "newCity" || e.target.value == 'Уĸазать другой') {
            // setData({ ...data, city: null })
            setActiveCity(true);
        } else {
            setActiveCity(false);
            setData({ ...data, city: e.target.value.trim() })
        }
    }
    const handleChange = (e) => {
        setData({ ...data, cityText: e.target.value.trim() });
    }
    return (
        <form onSubmit={handleSubmit}>
            <div>Рад знаĸомству, {data.username}! Рассĸажите, из ĸаĸого вы города, таĸ я буду знать, где вы живете и смогу предложить лучшие условия по доставĸе.</div>
            {/* <div style={{ margin: "1rem 0" }}>Пожалуйста, выбери город из списка или воспользуйся ручным вводом если ваш город отсутствует.</div> */}
            <div>
                <select value={data.city} onChange={onChangeCity} className={'select'}>
                    <option value={'Default'}>Выберите город из списка</option>
                    <option value={'Будва'}>Будва</option>
                    <option value={'Бар'}>Бар</option>
                    <option value={'Тиват'}>Тиват</option>
                    <option value={'Котор'}>Котор</option>
                    <option value={'Херцог-Нови'}>Херцог-Нови</option>
                    <option value={'newCity'}>Уĸазать другой</option>
                </select>
            </div>
            {(activeCity) && <div>
                <input
                    type="text"
                    id="username"
                    className="input"
                    placeholder="Напишите название вашего города или деревни."
                    value={data.cityText}
                    onChange={handleChange}
                    required />
            </div>}
            {/* {
                ((data.city && data.city !== 'Default' && data.city !== "Выберите город из списка") || data.cityText?.length > 3) && <button className="button" type="submit">
                    <span>Продолжить</span>
                </button>
            } */}
        </form >
    );
}

export default CitySingUp;